.calendar {
    min-height: $mainContainerHeight; // minus nav and footer sizes
    padding-top: 0;

    h1 {
        padding-top: 30px;
        margin-top: 0;
    }

    &-large {
        display: none;

        @include large {
            display: block;
        }
    }

    &-small {
        @include large {
            display: none;
        }
    }
}

.calendar-wrapper {
    iframe {
        width: 100%;

        @include large {
            width: 90%;
        }
    }

    .ctas {
        display: block;
        margin: 20px 0;

        .button-primary {
            display: inline-block;
            margin: 15px 0;
        }
    }
}