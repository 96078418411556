.home-page-hero {
    // background: url('../../public/images/jenny-stretch.jpg');
    background-color: $color-secondary;
    padding: 50px 20px;
    color: $color-light;
    background-position: bottom center;
    background-size: cover;
    min-height: $mainContainerHeight; // minus nav and footer sizes
    box-sizing: border-box;
    opacity: 0.5;
    transition: height 2s ease-in-out;

    @include large {
        background-position: right center;
        margin: 0 auto;
    }
}

.full {
    .content-wrapper {
        position: relative;
        padding: 30px 15px;
        text-align: center;
        max-width: 500px;

        @include large {
            left: 0;
            transform: none;
            width: 50%;
            left: 10%;
        }

        &:before {
            content: "";
            display: block;
            background-color: $color-main;
            opacity: $opacity-light;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        .button-primary {
            margin-top: 20px;
            display: inline-block;
        }

        p {
            line-height: 1.5;
        }

        &--full {
            width: 90%;
            max-width: 900px;
            left: 0;
            margin: 0 auto;
            transform: none;
            top: 0;
        }

        h1 {
            color: $color-light;
            font-size: 24px;
            text-align: left;
            margin-left: 23px;
        }

        * {
            position: relative;
            display: block;
        }

        strong {
            display: inline;
        }

        li {
            margin-bottom: 15px;
            text-align: left;
            margin-bottom: 15px;
            text-align: left;
            list-style: disc;
            margin-left: 40px;
            display: list-item;

            a {
                text-decoration: none;
                color: $color-light;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .blog-list {
            @include large {
                display: grid;
                grid-template-columns: 33% 33% 33%;
            }
        }
    }
}