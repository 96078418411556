p, a, main, section, html, body, div, span, ul, li, button, h1, h2, h3, h4 {
    border: none;
    list-style: none;
    margin: 0;
    padding: 0;
}

ol {
    list-style: decimal;
    padding-left: 0;

    li {
        list-style: decimal;
        list-style-position: outside;
        padding: 5px 0;
        margin: 0 15px;
    }
}

body {
    font-size: 16px;
    color: $color-dark;
    background-color: $color-main;

    @include large {
        font-size: 18px;
    }
}

p, a, li, address {
    font-style: normal;
}

address {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 25px;
}

iframe {
    width: 90%;
    margin: 0 auto 30px;
    display: block;
}

p {
    margin-bottom: 15px;
}

h1 {
    font-size: 24px;
    line-height: 1.5;
    color: $color-main;
    margin-bottom: 15px;
    text-transform: uppercase;
}

h2 {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 15px;
    text-transform: uppercase;
}

h3 {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 15px;
    text-transform: uppercase;
}

main {
    padding: 10px 25px;
    max-width: 900px;
    margin: 0 auto;
    min-height: $mainContainerHeight;
    background-color: $color-light;

    @include large {
        min-height: $mainContainerHeight;
    }

    &.full {
        padding: 0;
        max-width: none;
    }
}

.main-wrapper {
    background-color: $color-light;
}

p > a {
    color: $color-main;
    text-decoration: none;

    &:hover {
        border-bottom: 1px solid $color-main;
    }
}