footer {
    background: $color-main;
    color: $color-light;
    padding: 15px;
    border-top: 3px solid $color-secondary;

    a {
        color: $color-light;
        font-size: 1em;
        text-decoration: none;

        &:hover {
            border-bottom: 1px solid;
        }
    }

    .footer-content {
        max-width: 900px;
        display: flex;
        margin: 0 auto;
        flex-wrap: wrap;
        flex-direction: column;
        font-size: 16px;
    }

    img {
        height: 15px;
    }
}