.center-spinner {
    width: 100%;
    text-align: center;
    height: 200px;
    padding-top: 50px;

    @include large {
        width: 50%;

        .blog-article & {
            width: 450px;
            margin: 0 auto;
        }
    }
}

.full-page {
    height: 100vh;
    width: 100vw;
    position: relative;
}

.spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .spinner:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $color-secondary;
    border-color: $color-secondary transparent $color-secondary transparent;
    animation: spinner 1.2s linear infinite;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
