.button-primary {
    padding: 10px 20px;
    border-radius: 2px;
    color: $color-main;
    background-color: $color-light;
    transition: background-color 1s;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid $color-main;
    font-size: 16px;
   
    &:hover {
        background-color: $color-main;
        color: $color-light;
        border: 1px solid $color-light;
    }
}