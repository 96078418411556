nav {
    margin-bottom: 15px;

    a {
        color: $color-main;
        padding: 10px;
        text-decoration: none;
    }

    ul {
        display: flex;
        justify-content: center;
    }

    li+li:before {
        content: '/';
        color: $color-main;
    }
}

#main-nav {
    background: $color-main;
    color: $color-light;
    height: auto;
    display: flex;
    padding: 0;
    margin: 0;
    border-bottom: 3px solid $color-secondary;
    position: sticky;
    top: 0;
    z-index: 1;

    @include large {
        position: relative;
    }

    .overlay-bg {
        display: none;

        @include large {
            display: none;
        }

        &.active {
            display: block;
            height: 100%;
            width: 100%;
            background: $color-main;
            opacity: 75%;
            left: 0;
            top: 0;
            position: fixed;
            z-index: 0;

            @include large {
                display: none;
            }
        }
    }

    .main-nav {
        &__wrapper {
            max-width: 900px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            box-sizing: border-box;

            @include large {
                flex-direction: row;
            }
        }
    }

    a {
        color: $color-light;
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 15px;
        justify-content: center;

        @include large {
            height: 100%;
            padding: 0;
            border-bottom: 1px solid transparent;
        }

        &.logo {
            padding: 0;
            z-index: 1;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding: 10px 20px;
            box-sizing: border-box;

            @include large {
                width: auto;
            }
        }

        &.active {
            @include large {
                text-decoration: none;
                border-bottom: 1px solid $color-light;
                padding-bottom: 0px;
                height: auto;
            }
        }
    }

    .logo {
        span {
            font-size: 30px;
        }
    }

    img {
        height: 35px;
        padding-right: 10px;
        filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 25%));
        display: none;

        @include large {
            display: block;
        }
    }

    .specialty {
        text-shadow: 1px 1px 1px rgb(0 0 0 / 10%);
    }

    .nav-links {
        display: none;
        text-transform: uppercase;
        // position: absolute;
        right: 0;
        top: $topnavHeight;
        width: 100%;
        background: $color-light;
        z-index: 1;
        height: auto;

        &.active {
            display: block;
            border-bottom: 1px solid $color-light;

            @include large {
                display: flex;
                position: relative;
                border: none;
            }
        }

        li {
            background: $color-main;
            border-top: 1px solid $color-light;

            @include large {
                border: none;
                display: flex;
                align-items: center;
                padding: 10px;
            }
        }

        li+li:before {
            content: none;
        }

        @include large {
            display: flex;
            position: relative;
            width: auto;
            top: 0;
        }

        .material-symbols-outlined {
            position: static;
            height: auto;
        }
    }

    .material-symbols-outlined {
        text-shadow: 1px 1px rgb(0 0 0 / 10%);
        z-index: 1;
        position: absolute;
        right: 20px;
        height: 56px;
        display: flex;
        align-items: center;

        @include large {
            display: none;
        }
    }
}

.studio nav {
    ul {
        margin: 0 auto;
        flex-wrap: wrap;
    }

    a {
        margin: 10px 5px;
        padding: 0;

        &:hover {
            border-bottom: 1px solid $color-main;
        }
    }

    .active {
        border-bottom: 1px solid $color-main;
    }
}