.blog {
    &-article {

        h1 {
            line-height: 1.25;
        }

        .date,
        .return-link {
            font-size: 0.75em;
        }

        ul li {
            margin: 15px;
            list-style: disc;
        }

        img {
            width: 90%;

            @include large {
                max-width: 450px;
                margin: 0 auto 20px;
                display: block;
            }
        }

        &-wrapper {
            @include large {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                position: relative;
            }
        }

        &-content {
            width: 100%;
            margin-right: 45px;
        }
    }

    &-list-container {
        background: $color-main;
        color: $color-light;
        padding: 25px;
        border: 3px solid $color-secondary;
        width: 100%;
        box-sizing: border-box;

        @include large {
            width: 350px;
            position: sticky;
            top: 10px;
        }

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}