// colors
$color-main: #017E8E; // #048181; // #5CCDCD; // rgba(92, 205, 205, 65%);
$color-light: #FFFFFF;
$color-dark: #3A3A3A;
$color-secondary: #01ABC1;

// opacity
$opacity-light: 50%;
$opacity-dark: 80%;
$opacity-full: 100%;

// border-radius
$border-radius: 50px;

// size variables
$small: 480px;
$large: 768px;

// element heights
$topnavHeight: 59px; // 56 + 3 bottom border
$footerHeight: 79px; // 76 + 3 top border
$mainContainerHeight: calc(100vh - #{$topnavHeight + $footerHeight});

// mixins & breakpoints
@mixin large {
    @media (min-width: #{$large}) {
        @content;
    }
}

@mixin small {
    @media (min-width: #{$small}) {
        @content;
    }
}
