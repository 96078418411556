.rect {
    background: $color-main;
}

h1 {
    @include large {
        margin-top: 20px;
        font-size: 36px;
    }
}

.wrapper {
    position: relative;

    @include large {
        display: flex;
        margin-top: -#{$topnavHeight};
    }

    &::before {
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $color-main;
        width: 100%;
        margin: 0 auto;
        border: 3px solid #01ABC1;
        box-sizing: border-box;

        @include large {
            content: none;
            border: none;
        }
    }

    div {
        position: relative;
    }

    section {
        position: relative;
        padding: 20px;
        text-align: center;
        
        @include large {
            border: 3px solid $color-secondary;
            width: 50%;
            margin-left: auto;
            background: $color-main;
            padding-top: 60px;
        }
    }

    img {
        width: 105%;
        padding-top: 18px;
        left: -2.5%;
        position: relative;

        @include large {
            height: auto;
            width: 60%;
            position: absolute;
            z-index: 1;
            left: -10%;
            margin-top: 60px;
            padding-top: 0;
        }
    }

    p {
        color: $color-light;
        text-align: center;

        @include large {
            padding-left: 10%;
            text-align: left;
        }

        > a {
            color: $color-light;

            &:hover {
                border-bottom: 1px solid $color-light;
            }
        }
    }

    .logo {
        width: 75px;
        margin: 0 auto 20px;
        display: block;
        left: 0;
        padding-top: 0;
        border-bottom: 1px dotted $color-light;
        padding-bottom: 10px;

        @include large {
            position: relative;
            height: auto;
            width: 100px;
            margin-bottom: 30px;
            border-radius: 0;
        }
    }

    .button-primary {
        display: inline-block;
        margin-bottom: 25px;
    }

    h2 {
        font-size: 22px;
        color: $color-light;
        text-transform: none;
        text-align: left;
    }

    .list-item {
        margin-bottom: 25px;

        @include large {
            padding-left: 10%;
            text-align: left;
        }

        h2 {
            margin-bottom: 5px;
            font-size: 18px;
        }

        p {
            margin-bottom: 5px;
            text-align: left;
            padding-left: 25px;
        }
    }

    .left {
        text-align: left;
    }

    .socials {
        p {
            text-align: center;

            a {
                border: none;

                &:hover { 
                    border: none;
                }
            }
        }
    }
}