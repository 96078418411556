.socials {
    a {
        
    }
}

.location-group {
    @include large {
        margin-top: 65px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    section {
        @include large {
            width: 100%;
        }
    }
}
