.socials {
    text-align: center;
    margin-bottom: 15px;

    .zumba-logo {
        width: auto;
        height: 25px;
        display: inline-block;

        .wrapper & {
            filter: invert(1);
        }
    }

    img {
        text-align: center;
        width: 100%;
        height: 100%;
        margin-top: 0;
        position: relative;
        left: 0;
        padding-top: 0;
    }

    span {
        font-size: 23px;
    }

    p {
        display: flex;
        justify-content: space-evenly;
        max-width: 250px;
        margin: 0 auto 5px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                border: none;
            }
        }
    }
}